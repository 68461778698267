import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import router from './router';

import axios from 'axios';

axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;

axios.defaults.timeout = 90000;
axios.defaults.retry = 2;
axios.defaults.retryDelay = 2000;

// fnResize();
// window.onresize = function () {
//   fnResize();
// }
// function fnResize() {
//   var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
//   if (deviceWidth >= 750) {
//     deviceWidth = 750;
//   }
//   if (deviceWidth <= 320) {
//     deviceWidth = 320;
//   }
//   document.documentElement.style.fontSize = (deviceWidth / 7.5) + 'px';
// }

// 页面跳转回到顶部
router.afterEach(() => {
  window.scrollTo(0,0)
})


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
