<template>
  <div>
    <img src="../../assets/4.jpg" style="width: -webkit-fill-available" />
    <div class="item-box">
      <img src="../../assets/4-1.jpg" class="left-img" />
      <div class="text-box">
        <h3>HONGKONG OFFICE</h3>
        <h4 class="main-text">
          HONGKONG OFFICE <br />
          A GREEN TECH COMPANY LIMITED <br />
          2/F YAU TAK BUILDING 167 LOCKHART ROAD WANCHAI,HONG KONG<br />
          Email:mark@agreenchips.com
        </h4>
      </div>
    </div>

    <div class="item-box">
      <img src="../../assets/4-2.jpg" class="left-img" />
      <div class="text-box">
        <h3>SHENZHEN OFFICE</h3>
        <h4 class="main-text">
          SHENZHEN OFFICE <br />
          317 ,Block 505,Shangbu Industrial Zone, Zhenxing Road, Futian Dist.,
          ShenZhen,China . 518028 <br />
          Tel: 86-755-82733376 <br />
          Fax: 86-755-23601350 <br />
          Email: fish.lau94@yahoo.com<br />
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; fish.lau@agreenchips.com
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.item-box {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}
.left-img {
  width: 350px;
}
.summary-item {
  text-align: left;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
.main-text {
  line-height: 25px;
  text-align: left;
  font-size: 15px;
  /* font-weight: 600; */
}
</style>