<template>
  <div class="search_result">
    <el-dialog
      title="consultation"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="name">
          <el-input v-model="form.contact_name"></el-input>
        </el-form-item>
        <el-form-item label="Contact details">
          <el-input v-model="form.contact_number"></el-input>
        </el-form-item>
        <el-form-item label="Inquiry content">
          <el-input
            v-model="form.contact_note"
            type="textarea"
            :autosize="{ minRows: 2 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 图片显示弹窗 -->
    <el-dialog :visible.sync="imgVisible">
      <el-image :src="imgUrl">
        <!-- :preview-src-list="scope.row.pictures"> -->
      </el-image>
    </el-dialog>

    <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <el-input
          placeholder="Please enter the model number"
          v-model="input"
          class="input-search-bar"
        >
          <template slot="append">
            <el-button
              icon="el-icon-search"
              @click="searchBtnClicked"
            ></el-button>
          </template>
        </el-input>
      </el-col>
      <el-col :span="4" style="text-align: right">
        <el-badge :value="allNum" class="item">
          <i
            class="el-icon-shopping-cart-full icon_class"
            @click="openCart"
          ></i>
        </el-badge>
      </el-col>
    </el-row>
    <el-table
      v-loading="isLoading"
      :data="tableData"
      stripe
      style="width: 100%; min-height: 400px; height: calc(100vh-260px)"
      border
      :header-cell-style="{ background: '#5f7686', color: '#fff' }"
    >
      <el-table-column prop="model" label="Part Number" align="center">
      </el-table-column>
      <el-table-column prop="factory" label="Manufactory" align="center">
      </el-table-column>
      <el-table-column
        prop="package_specification"
        label="Package"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="year_batch" label="Date Code" align="center">
      </el-table-column>
      <el-table-column prop="in_stock" label="Quantity in stock" align="center">
      </el-table-column>
      <el-table-column prop="price" label="U/P" align="center" width="210">
        <template slot-scope="scope">
          <!-- <div v-for="(pri, index) in scope.row.price" :key="index">
            {{ pri.x + ":  " + pri.y }}
          </div> -->
          <ul class="price-list">
            <li>
              <span class="label">1-20pcs：</span
              ><span>{{ scope.row.price }}</span>
            </li>
            <li>
              <span class="label">20-100pcs：</span
              ><span>{{ scope.row.price * 0.9 }}</span>
            </li>
            <li>
              <span class="label">>100pcs：</span
              ><span>{{ scope.row.price * 0.8 }}</span>
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        prop="delivery_time"
        label="Delivery time"
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column prop="picture" label="图片" align="center"
        ><template slot-scope="scope">
          <el-link
            type="primary"
            v-if="scope.row.picture != null && scope.row.picture != ''"
            @click="openImgDialog(scope.row.pictures)"
          >
            <i class="el-icon-picture el-icon-img"></i>
          </el-link>
        </template>
      </el-table-column> -->
      <el-table-column
        :context="_self"
        fixed="right"
        label="Operate"
        align="center"
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            v-if="scope.row.picture != null && scope.row.picture != ''"
            @click="openImgDialog(scope.row.pictures)"
          >
            <i class="el-icon-picture el-icon-img"></i>
          </el-link>
          <el-popover
            placement="top"
            width="160"
            :ref="`popover-${scope.$index}`"
          >
            Quantity<el-input-number
              v-model="scope.row.num"
              :min="0"
              size="mini"
              label="描述文字"
            ></el-input-number>
            <div style="text-align: right; margin: 2px">
              <el-button size="mini" type="text" @click="pClose(scope.$index)"
                >Cancel</el-button
              >
              <el-button
                size="mini"
                type="primary"
                :disabled="scope.row.num < 1"
                @click="addToCart(scope.$index)"
                >OK</el-button
              >
            </div>
            <el-button slot="reference" type="text" size="small">
              <i class="el-icon-shopping-cart-full el-icon-img"></i>
            </el-button>
          </el-popover>
          <el-button
            type="text"
            size="small"
            @click="askForInfomation(scope.row)"
            ><i class="el-icon-chat-dot-square el-icon-img"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 购物车弹窗 -->
    <el-drawer title="My shopping cart" :visible.sync="drawer" direction="rtl">
      <div class="cart_item" v-for="(item, index) in cartNumList" :key="index">
        <el-row style="text-align: left">{{ item.model }}</el-row>
        <el-row>
          <el-col :span="12" :offset="0">
            <el-input-number
              v-model="item.num"
              @change="handleChange"
              :min="1"
              size="mini"
              label="Description"
            ></el-input-number>
          </el-col>
          <el-col :span="12" :offset="0" style="text-align: right">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deletClick(index)"
            ></el-button>
          </el-col>
        </el-row>
      </div>

      <div
        v-if="cartNumList && cartNumList.length > 0"
        style="text-align: right; margin: 10px"
      >
        <el-button type="primary" size="mini" @click="sendCartData" plain
          >Send a purchase intent</el-button
        >
      </div>
      <div v-else>No data yet!</div>
      <el-divider></el-divider>
    </el-drawer>
  </div>
</template>

/* eslint-disable */
<script>
import Constant from "../components/common/Constant.vue";

export default {
  data() {
    return {
      isLoading: false,
      form: {
        contact_name: "",
        contact_number: "",
        contact_note: "",
      },
      input: "",
      tableData: [],
      dialogVisible: false,
      testDic: {
        model: "DP83848IVV/NOPB",
        factory: "TI",
        package_specification: "LQFP48",
        year_batch: "2021+",
        in_stock: 1800,
        price: [
          { x: "1 - 20", y: "$4" },
          { x: "21 - 200", y: "$40" },
          { x: "200 以上", y: "$4" },
        ],
        delivery_time: "3-5天",
        picture:
          "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        pictures: [
          "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
          "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
        ],
      },
      imgVisible: false,
      imgUrl: "",
      cartNumList: [],
      drawer: false,
      allNum: 0,
    };
  },
  mounted() {
    this.input = this.$route.query.id;
    this.searchBtnClicked();
  },
  methods: {
    searchBtnClicked() {
      // this.tableData = [];
      // this.tableData.push(this.testDic);
      // this.tableData.push(this.testDic);
      this.searchProductByModel(this.input);
    },

    addProduct(product) {
      console.log("product:", product);
    },
    //添加到购物车
    addToCart(index) {
      const additem = this.tableData[index];
      if (additem.num < 1) {
        return;
      }
      let aa = this.cartNumList.findIndex((item) => {
        return item.model === additem.model;
      });
      if (aa != -1) {
        this.cartNumList[aa].num = this.cartNumList[aa].num + additem.num;
      } else {
        this.cartNumList.push({
          model: additem.model,
          num: additem.num,
        });
      }
      this.tableData[index].visible = false;
      // this.allNum = this.allNum + this.tableData[index].num
      this.allNum = this.cartNumList.reduce((sum, e) => sum + e.num, 0);
      this.pClose(index);
      this.tableData[index].num = 1;
    },
    //咨询
    askForInfomation(param) {
      console.log(param);
      this.dialogVisible = true;
    },
    //展示图片
    openImgDialog(url) {
      this.imgVisible = true;
      this.imgUrl = url;
    },
    //根据model查询产品
    searchProductByModel(model) {
      console.log("searchProductByModel");
      let searchModelApi =
        Constant.SERVER_PRE_URL + "/product/search/?model=" + model;
      console.log("searchModelApi:", searchModelApi);
      this.isLoading = true;
      this.$http
        .get(searchModelApi)
        .then((response) => {
          this.isLoading = false;
          console.log(response.data);
          let json = response.data;
          if (json.errorCode == 0) {
            this.$message.success("Success");
            this.tableData = JSON.parse(JSON.stringify(json.content));
          } else {
            this.$message.error(json.text);
          }
          this.tableData.forEach((element) => {
            // Object.assign(element, {num: 1, visible:false})
            this.$set(element, "num", 0);
            this.$set(element, "visible", false);
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$message.error("失败,code = 2");
          console.log(error);
        });
    },
    //关闭咨询框
    handleClose() {
      this.dialogVisible = false;
    },
    openCart() {
      this.drawer = true;
      console.log("222");
    },
    handleChange() {
      // this.cartNumList[index].num = val;
      this.allNum = this.cartNumList.reduce((sum, e) => sum + e.num, 0);
    },
    deletClick(index) {
      this.cartNumList.splice(index, 1);
    },
    pClose(index) {
      // this.$refs['elPopover'].doClose()
      this.$refs[`popover-` + index].doClose();
    },
    sendCartData() {
      if (!sessionStorage.getItem("userName")) {
        this.$message.error("您还未登陆账号，请先登陆");
        return;
      }
      this.$confirm(
        "此操作将会将购物车的购买意向发送给我公司邮箱, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let searchModelApi = Constant.SERVER_PRE_URL + "/ask/order";
          const params = {
            user_email: sessionStorage.getItem("userName"),
            user_name: "",
            question: this.cartNumList.toString(),
          };
          this.$http
            .post(searchModelApi, params)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              this.$message.error("失败,code = 2");
              console.log(error);
            });
          this.$message({
            type: "success",
            message: "发送成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消发送",
          });
        });
    },
  },
};
</script>

<style scoped>
.input-search-bar {
  /* width: 300px; */
}

.el-row {
  margin-bottom: 20px;
}

.price-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.price-list li:first-child {
  border-top: 0px dashed #d4d4d4;
}
.price-list li {
  overflow: hidden;
  border-top: 1px dashed #d4d4d4;
  padding: 1px 0;
}
.price-list li .label {
  float: left;
}

.search_result {
  /* padding-left: 1.5em;
  padding-right: 1.5em;
  margin-left: auto;
  margin-right: auto;
  min-height: 515px;
  margin-top: 1.5em;
  margin-bottom: 1.5em; */
}

.icon_class {
  margin: 0px;
  font-size: 1.5em;
  vertical-align: middle;
  color: #409eff;
}

.cart_item {
  margin: 0px 20px;
}

.el-table .el-table__row--striped {
  color: aquamarine !important;
}
.el-icon-img {
  font-size: 20px;
  margin-left: 10px;
}
</style>