<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-row type="flex" justify="space-between">
          <el-col :span="4" style="text-align: left">
            <img
              src="./assets/geyun_logo.png"
              class="logo-image"
              height="60px"
              @click="showHomePage()"
            />
          </el-col>

          <el-col :span="12" v-if="$route.path.indexOf('page') !== -1">
            <el-input
              placeholder="Please enter the model number"
              v-model="input"
              class="input-search-bar"
            >
              <template v-slot:append>
                <el-button
                  icon="el-icon-search"
                  @click="searchBtnClicked(input)"
                ></el-button>
              </template>
            </el-input>
          </el-col>

          <el-col v-if="hasLogin === false" :span="4" style="text-align: right">
            <el-button type="text" @click="loginBtnClicked(true)"
              >Sign in
            </el-button>
            <el-button type="text" @click="loginBtnClicked(false)"
              >Register</el-button
            >
          </el-col>
          <el-col v-else :span="4" style="text-align: right">
            <i class="el-icon-user"
              ><span style="margin-left: 10px">{{ loginEmail }}</span></i
            >
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-aside class="aside-box">
          <div class="left_box">
            <a href="mailto:fish.lau@agreenchips.com">
              <img src="./assets/email.png" class="left-icon" />
            </a>
            <a href="https://api.whatsapp.com/send?phone=+8613428982047">
              <img src="./assets/whateapp.png" class="left-icon" />
            </a>
            <el-popover placement="top-start" title="qq" trigger="hover">
              <img src="./assets/qqmy.jpeg" style="width: 250px" />
              <img slot="reference" src="./assets/qq.png" class="left-icon" />
            </el-popover>
            <el-popover placement="top-start" title="wechat" trigger="hover">
              <img src="./assets/wechatmy.jpeg" style="width: 250px" />
              <img
                slot="reference"
                src="./assets/wechat_icon.png"
                class="left-icon"
              />
            </el-popover>
            <!-- <img src="./assets/downline.png" class="left-icon" /> -->
          </div>
        </el-aside>
        <router-view> </router-view>
        <div class="footer" style="height: 200px">
          <div class="footer_box">
            <div @click="goPage(1)">
              <el-row class="footer-row-title">A GREEN BUSINESS</el-row>
              <div class="footer-list">Exceed inventory service</div>
              <div class="footer-list">Globle purchasing</div>
              <div class="footer-list">Sales service</div>
            </div>
            <div @click="goPage(2)">
              <el-row class="footer-row-title">Why A GREEN</el-row>
              <div class="footer-list">Quality Management</div>
              <div class="footer-list">Packing & Delivery</div>
            </div>
            <div @click="goPage(3)">
              <el-row class="footer-row-title">Components search</el-row>
              <div class="footer-list">Purchasing Process</div>
              <div class="footer-list">BOM Purchase</div>
            </div>
            <div @click="goPage(4)">
              <el-row class="footer-row-title">Contact</el-row>
              <div class="footer-list">Sales offices</div>
              <div class="footer-list">Email to us</div>
              <div class="footer-list">Email：fish.lau@agreenchips.com</div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>

    <el-dialog
      :title="islogin ? 'Sign in' : 'Register'"
      :visible.sync="loginVisible"
      width="480px"
    >
      <el-form size="small" label-width="80px">
        <el-form-item label="mailbox：" prop="loginEmail" :rules="loginrule">
          <el-input
            placeholder="Please enter your email address"
            v-model="loginEmail"
          ></el-input>
        </el-form-item>
        <el-form-item label="password:">
          <el-input
            placeholder="Please enter a password"
            v-model="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer" class="dialog-footer">
        <el-button size="mini" @click="loginVisible = false">cancle</el-button>
        <el-button
          v-if="islogin"
          size="mini"
          type="primary"
          @click="loginWithEmail()"
          >Sign</el-button
        >
        <el-button
          v-else
          size="mini"
          type="primary"
          @click="registerWithEmail()"
          >enroll</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Constant from "./components/common/Constant.vue";

/* eslint-disable */
export default {
  name: "app",
  components: {
    // HelloWorld
  },
  data() {
    return {
      activeIndex: "1",
      input: "",
      loginVisible: false,
      loginEmail: "",
      password: "",
      hasLogin: false,
      islogin: true,
      loginrule: {
        loginEmail: [
          { required: true, message: "请填写电子邮箱", trigger: "blur" },
          // { validator: checkEmail, message: '邮箱格式不正确', trigger: 'change'}
        ],
      },
    };
  },
  mounted() {
    if (sessionStorage.getItem("userName")) {
      this.loginEmail = sessionStorage.getItem("userName");
      console.log("username--", this.loginEmail);
      this.hasLogin = true;
    }
  },
  methods: {
    checkEmail(rule, value, cb) {
      const regEmail =
        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.loginEmail != "" && !regEmail.test(value)) {
        return cb;
      }
      cb(new Error("Please enter valid email"));
    },
    showHomePage() {
      console.log("显示主页");
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
    searchBtnClicked(param) {
      if (this.$route.path != "/search_result") {
        this.$router.push({
          name: "SearchResult",
          query: { id: param },
        });
      }
    },
    loginBtnClicked(islogin) {
      console.log("loginBtnClicked");
      this.islogin = islogin;
      this.loginVisible = true;
    },
    loginWithEmail() {
      console.log("loginWithEmail");
      let loginWithEmailApi =
        Constant.SERVER_PRE_URL +
        "/user/login/?email=" +
        this.loginEmail +
        "&password=" +
        this.password;
      console.log("loginWithEmail:", loginWithEmailApi);
      this.$http
        .get(loginWithEmailApi)
        .then((response) => {
          console.log(response.data);
          let json = response.data;
          if (json.errorCode == 0) {
            this.hasLogin = true;
            this.loginVisible = false;
            this.$message.success("登录成功");
            sessionStorage.setItem("isLogin", true);
            sessionStorage.setItem("userName", this.loginEmail);
          } else {
            this.$message.error(json.text);
            this.hasLogin = false;
          }
        })
        .catch((error) => {
          this.$message.error("登录失败,code = 2");
          console.log(error);
        });
    },
    registerWithEmail() {
      let registerWithEmailApi =
        Constant.SERVER_PRE_URL +
        "/user/register/?user_email=" +
        this.loginEmail +
        "&password=" +
        this.password;
      console.log("loginWithEmail:", registerWithEmailApi);
      this.$http
        .get(registerWithEmailApi)
        .then((response) => {
          console.log(response.data);
          let json = response.data;
          if (json.errorCode == 0) {
            this.hasLogin = true;
            this.loginVisible = false;
            this.$message.success("登录成功");
          } else {
            this.$message.error(json.text);
          }
        })
        .catch((error) => {
          this.$message.error("注册失败,code = 2");
          console.log(error);
        });
    },
    goPage(num) {
      this.$router.push("/page" + num);
    },
  },
};
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ededed;
  margin-top: -5px;
}

.el-container {
  height: 100%;
}

.el-header {
  background-color: #ededed;
  color: #333;
  text-align: center;
  line-height: 60px;
  padding-left: 12% !important;
  padding-right: 12% !important;
}

.el-footer {
  line-height: 60px;
  /* background-color: #e7e7e7; */
  background-color: #ededed;
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 0;
  padding-left: 12% !important;
  padding-right: 12% !important;
}

.el-main {
  /* background-color: #ededed; */
  color: #333;
  text-align: center;
  position: absolute;
  left: 8px;
  right: 8px;
  top: 60px;
  overflow-y: scroll;
  padding-left: 12% !important;
  padding-right: 12% !important;
}
.aside-box {
  display: flex;
  /* justify-content: center; */
  position: fixed;
  top: 50%;
  margin: auto;
  -webkit-transform: translate(-50%, -50%);
  /* display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 30%;
  width: 50%;
  margin: auto;
  padding-left: 9% !important; */
}

.footer {
  display: flex;
  align-items: center;
  /* background-color: #ededed; */
}

.footer-list {
  line-height: 30px;
  text-align: left;
  cursor: pointer;
  color: #8f8f8b;
  font-weight: 500;
}
.footer-list:hover {
  color: rgba(27, 27, 27, 0.795);
}

.footer-row-title {
  font-size: 1.1em;
  text-align: left;
  color: #8f8f8b;
  font-weight: 500;
}
.footer_box {
  display: flex;
  flex: 1;
  justify-content: space-around;
  /* background-color: #ededed; */
}
.left_box {
  max-width: 100px;
  display: flex;
  flex-direction: column;
}
.left-icon {
  width: 30px;
  height: 30px;
  padding: 2px;
}
</style>
