<template>
  <div>
    <img src="../../assets/3.jpg" style="width: -webkit-fill-available" />
    <div class="item-box">
      <img src="../../assets/3-1.jpg" class="left-img" />
      <div class="text-box">
        <h3>Purchasing process</h3>
        <h4>Select the electronic components purchased by A Green</h4>
        <div class="main-text">
          1，Search for the component model on the website, and click the Email
          to communicate,checking the part number , quantity,manufatory and
          required date code. <br />
          2，A Green will reply the most accurate unit price, date code,
          delivery date and packaging of the goods within 12 hours, and
          transportation cost to you. <br />
          3，If a cooperation agreement is reached, you can place your order to
          us,negotiate the payment method and the bank fees. For the first
          cooperation,TT in advance is acceptable. If the amount is relatively
          large, Escrow is chosen. If everyone has cooperated successfully for
          many times, we can negotiate COD or Net terms. <br />
          4，If everything is agreed and we receive the payment, shipment will
          be finished within 24 hours, and provide Tracking number and customs
          declaration number. <br />
          5，After you receives the goods for acceptance. For cash on delivery
          or Net terms, please fullfill the balance within 3 days after
          acceptance. <br />
        </div>
      </div>
    </div>

    <div class="item-box">
      <img src="../../assets/3-2.jpg" class="left-img" />
      <div class="text-box">
        <h3>Bom purchases</h3>
        <h4>Select the electronic components purchased by A Green</h4>
        <div class="main-text">
          1，Please EMAIL to the sales , for the first time, please contact the
          company mailbox. <br />
          2，Please send the BOM attachment and note/details the requirements
          you needs to the salesman . <br />
          3，We will reply within 24 hours for all the goods with accurate stock
          status, date code, unit price, delivery date and packaging. <br />
          4，If your company needs to purchase part of it, or entrust it to
          purchase all of it, A Green salesman will tell you the full delivery
          time, total weight of the goods, packaging, and transportation costs
          to you.<br />
          5，If everything is agreed and we receive the payment, shipment will
          be finished within the required time, , and provide Tracking number
          and customs declaration number.<br />
          6，After you receives the goods for acceptance. For cash on delivery
          or Net terms, please fullfill the balance within 3 days after
          acceptance. <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.item-box {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}
.left-img {
  width: 350px;
}
.summary-item {
  text-align: left;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
.main-text {
  line-height: 25px;
  text-align: left;
  font-size: 15px;
  /* font-weight: 600; */
}
</style>