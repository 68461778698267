<template>
  <div>
    <img src="../../assets/1.jpg" style="width: -webkit-fill-available" />
    <div class="item-box">
      <img src="../../assets/1-1.jpg" class="left-img" />
      <div class="text-box">
        <h3>Exceed Inventory Management</h3>
        <h4>Put your exceed inventory under the management of A Green.</h4>
        <div class="summary-item">
          The process from maintaining inventory to transportation and
          warehousing costs a lot of inventory management costs. However, a
          professional ERP inventory management solution can quickly improve
          operational efficiency and allow you to easily understand inventory
          status.
        </div>
        <div class="main-text">
          Provide quality inventory management responsibility. <br />
          ·Provide a variety of inventory models, including customer-owned
          exceed inventory and A Green's spot inventory <br />
          ·Using A Green's warehouse management team and storage space can
          reduce your warehouse labor costs. <br />
          ·With strictly controlled, providing practical protection, and
          anti-static , Ambient temperature control to protect your Exceed
          inventory. <br />
          ·Rely on A Green's central operating location to manage your demand
          from all regions.<br />
          ·A Green provides a variety of service options such as constant
          temperature antistatic storage, antistatic packaging, tape and reel
          packaging, etc. <br />
          ·A Green can plan, inspect, maintain and replenish inventory to meet
          your specification standards
        </div>
      </div>
    </div>

    <div class="item-box">
      <img src="../../assets/1-2.jpg" class="left-img" />
      <div class="text-box">
        <h3>Bom Procurement</h3>
        <h4>What is Bom Procurement</h4>
        <div class="summary-item">
          A Green's Package Purchasing service can optimize your material
          purchasing list and maximize purchasing efficiency. We will negotiate
          with you to comprehensively place the key components and even all the
          purchased materials you need, and mark them according to your
          specifications for easy access at any time.
        </div>
        <div class="main-text">
          From the beginning,all components required for testing are sorted and
          ready: <br />
          ·Integrate the parts you need into fully customizable kits based on
          your needs. <br />
          ·Reduce your costs and improve your operational efficiency. <br />
          ·Streamline the number of suppliers and the manpower. <br />
          ·A Green can purchase parts from your exceed inventory, or source
          directly from your nominated manufacturer or our network of trusted
          suppliers.<br />
          ·Receive boxes immediately to reduce inventory costs. <br />
          ·Ensure quality and manage settings even in challenging environments,
          such as field locations.<br />
          ·A Green can plan, inspect, maintain and replenish inventory to meet
          your specification standards
        </div>
      </div>
    </div>

    <div class="item-box">
      <img src="../../assets/1-3.jpg" class="left-img" />
      <div class="text-box">
        <h3>Purchasing Services</h3>
        <h4>Make business easier with A Green's Purchasing Services</h4>
        <div class="summary-item">
          Constantly monitoring and tracking the dynamics of the global
          electronic components market will occupy and consume a lot of manpower
          and resources for purchasing departments, especially they have to pay
          attention to acquisition costs and manage a large supplier base.
          Detecting real-time market information will add more challenges to
          their work. So when companies run into trouble sourcing the parts they
          need, it's time to find new ways to lighten their load and get back to
          your main business.
        </div>
        <div class="main-text">
          A Green Procurement Services can help you <br />
          ·A Green Procurement Services can help you <br />
          ·Reduce low transaction volume suppliers, merge similar or overlapping
          if necessary <br />
          ·Reduce intermediate links in the approval process to add
          sub-suppliers.<br />
          ·Professional procurement experts, global coverage, real-time updated
          market information, audit credit and reliable global supplier list.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   data() {
  //     return {
  //       inputVaule: "",
  //     }
  //   },
  //   methods: {
  //     searchBtnClicked(param) {
  //       console.log(param);
  //       this.$router.push({
  //         name: "SearchResult",
  //         query: { id: this.inputVaule },
  //       });
  //     }
  //   }
  // mounted () {
  //     this.$router.afterEach(() => {
  //         window.scrollTo(0,0)
  //     })
  // }
};
</script>

<style scoped>
.item-box {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}
.left-img {
  width: 350px;
}
.summary-item {
  text-align: left;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
.main-text {
  line-height: 25px;
  text-align: left;
  font-size: 15px;
  /* font-weight: 600; */
}
</style>