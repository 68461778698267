<template>
  <div>
    <img src="../../assets/2.jpg" style="width: -webkit-fill-available" />
    <div class="item-box">
      <img src="../../assets/2-1.jpg" class="left-img" />
      <div class="text-box">
        <h3>Quality Control</h3>
        <h4>Choose electronic chip products sourced by A Green</h4>
        <div class="summary-item">
          A Green promise that all the components we provided are all from the
          original factory and agents, and some of them come from the brand
          dealers and purchasing departments to check the layers, verify the
          original packaging labels of the original factory, or check whether
          the products have signs of oxidation.
        </div>
        <div class="main-text">
          A Green responsibility for your quality <br />
          ·All products are from original factory agents and distributors.<br />
          ·All products have order contracts and customs declarations,Traceable
          to the original factory. <br />
          ·Each shipment has the original factory label, and can be provided to
          the customer for verification before shipment.<br />
          ·For scattered quantities parts, we will provide professional
          anti-static packaging and vacuumize and within special desiccant.
          <br />
          ·All products are checked by a professional distribution team for the
          date code and batch code corresponding to the customer's needs before
          leaving the warehouse. <br />
          ·If the customer has a question for the quality,, we will provide a
          professional answer within 24 hours, or send it back to A Green for
          professional testing. <br />
        </div>
      </div>
    </div>

    <div class="item-box">
      <img src="../../assets/2-2.jpg" class="left-img" />
      <div class="text-box">
        <h3>Packing and shipping</h3>
        <h4>A Green Professional Logistics</h4>
        <div class="summary-item">
          A Green will use a special anti-static foam outside the original
          packaging of each product to prevent squeezing or collision during
          transportation, so that the integrity of the packaging after
          unpacking.
        </div>
        <div class="main-text">
          We use international brands for transportation<br />
          ·For oversea customers, you can choose the shipping methods freely.
          <br />
          ·We use FEDEX, UPS, DHL, TNT,SF Express and some liner logistic
          express etc. <br />
          ·In China, including Hong Kong and Taiwan, we only choose SF Express.
          <br />
          ·For long-term cooperation customers, we can negotiate monthly express
          delivery costs, which can save freight costs. <br />
        </div>
      </div>
    </div>

    <div class="item-box">
      <img src="../../assets/2-3.jpg" class="left-img" />
      <div class="text-box">
        <h3>Purchasing Services</h3>
        <h4>Make business easier with A Green's Purchasing Services</h4>
        <div class="summary-item">
          Constantly monitoring and tracking the dynamics of the global
          electronic components market will occupy and consume a lot of manpower
          and resources for purchasing departments, especially they have to pay
          attention to acquisition costs and manage a large supplier base.
          Detecting real-time market information will add more challenges to
          their work. So when companies run into trouble sourcing the parts they
          need, it's time to find new ways to lighten their load and get back to
          your main business.
        </div>
        <div class="main-text">
          A Green Procurement Services can help you <br />
          ·A Green Procurement Services can help you <br />
          ·Reduce low transaction volume suppliers, merge similar or overlapping
          if necessary <br />
          ·Reduce intermediate links in the approval process to add
          sub-suppliers.<br />
          ·Professional procurement experts, global coverage, real-time updated
          market information, audit credit and reliable global supplier list.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.item-box {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}
.left-img {
  width: 350px;
}
.summary-item {
  text-align: left;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
.main-text {
  line-height: 25px;
  text-align: left;
  font-size: 15px;
  /* font-weight: 600; */
}
</style>