<template>
  <div class="home">
    <br />
    <el-row class="big-title"> Find the components you want Now</el-row>
    <el-row style="padding: 10px 0">
      <el-col :span="12" :offset="6">
        <div class="search_top">Part No.Search</div>
        <el-input
          placeholder="Please enter the model number"
          v-model="inputVaule"
          class="input-search-bar"
        >
          <template slot="append">
            <el-button
              icon="el-icon-search"
              @click="searchBtnClicked"
            ></el-button>
          </template>
        </el-input>
      </el-col>
    </el-row>
    <el-row style="padding: 50px 0">
      <el-col :span="12" :offset="6">
        <el-carousel>
          <el-carousel-item v-for="(item, index) in imgList" :key="index">
            <el-image :src="item.url" fit="scale-down"></el-image>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <div class="bottom-box">
      <img src="../assets/home_page_img_1.jpg" class="bottom-img" />
      <img src="../assets/home_page_img_2.jpg" class="bottom-img" />
      <img src="../assets/home_page_img_3.jpg" class="bottom-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      inputVaule: "",
      imgList: [
        { url: require("@/assets/home_page_bottom_img1.jpg") },
        { url: require("@/assets/home_page_bottom_img2.jpg") },
        { url: require("@/assets/home_page_bottom_img3.jpg") },
      ],
    };
  },
  methods: {
    searchBtnClicked(param) {
      console.log(param);
      this.$router.push({
        name: "SearchResult",
        query: { id: this.inputVaule },
      });
    },
  },
};
</script>

<style scoped>
.home {
  /* height: 500px; */
}
.el-select .el-input {
  width: 130px;
}
.input-search-bar {
  background-color: #fff;
}
.big-title {
  font-size: 2em;
  margin: 20px 0;
  color: #8f8f8b;
  font-weight: 600;
}
.bottom-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bottom-img {
  width: 300px;
  height: 300px;
  padding: 5px;
}
.search_top {
  background: rgb(35, 187, 230);
  width: 120px;
  font-size: 16px;
  line-height: 30px;
  float: left;
  text-align: left;
  font-weight: 500;
  padding: 0 10px;
  color: #fff;
  border-radius: 4px 4px 0px 0px;
}
</style>
